<template>
  <div>
    <ViewTemplateWithSalveCancel :panel="panel">
      <div slot="content-buttons">
        <Button
          v-if="id"
          _key="btnCollaboratorCreate"
          title="Colaborador"
          classIcon="fas fa-plus-circle"
          type="primary"
          size="small"
          eventName="collaboratorCreate"
          :clicked="create"
        />
      </div>
      <div slot="content-main">
        <CollaboratorCreateUpdate :formName="panel.formName" />
      </div>
    </ViewTemplateWithSalveCancel>
  </div>
</template>

<script>

import ViewTemplateWithSalveCancel from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithSalveCancel";
import CollaboratorCreateUpdate from '../../../components/modules/human-resources/collaborator/CollaboratorCreateUpdate.vue'


import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CollaboratorCreateView",
  components: {
    Button,
    Molded,
    ViewTemplateWithSalveCancel,
    CollaboratorCreateUpdate,
  },
  data() {
    return {
      id: this.$route.params.id,
      panel: {
        module: "Recursos Humanos",
        title: "Colaborador",
        formName: "CollaboratorCreateUpdate",
        showFilter: false,
        showSearch: false,
        showButtons: true,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    create() {
      this.$router.push({
        name: "collaboratorCreate",
      });
      this.removeLoading(["btnCollaboratorCreate"]);
    },
  },
};
</script>
